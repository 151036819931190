import * as React from 'react';
import DefaultPage from '../../components/layouts/DefaultPage';
// import UserRegister from '../../components/forms/UserRegister/UserRegister';
// import UnauthenticatedContent from '../../components/UnauthenticatedContent/UnauthenticatedContent';
// import Tabs from '../../components/Tabs/Tabs';
// import Tab from '../../components/Tabs/Tab';
import UserLogin from '../../components/forms/UserLogin/UserLogin';
import {
    connect
} from 'react-redux';
import UnauthenticatedContent from '../../components/UnauthenticatedContent/UnauthenticatedContent';
import Tabs from '../../components/Tabs/Tabs';
import Tab from '../../components/Tabs/Tab';
import UserRegister from '../../components/forms/UserRegister/UserRegister';


const RegisterPage = ({
    activeLoginTab,
}) => {
    if ('jury' === process.env.GATSBY_PHASE) {
        return <DefaultPage>
            <UserLogin/>
        </DefaultPage>;
    } else {
        return <DefaultPage>
            <UnauthenticatedContent/>
            <Tabs
                className=""
                active={activeLoginTab}
                tabs={[<Tab
                    id="register"
                    label="Registrierung">
                    <UserRegister/>
                </Tab>, <Tab
                    id="login"
                    label="Login">
                    <UserLogin/>
                </Tab>,]}
            />
        </DefaultPage>;
    }
};

const mapStateToProps = ({
    activeLoginTab,
}) => {
    return {
        activeLoginTab,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAuthenticatedUser: ({
            user,
        }) => {
            return dispatch({
                type: 'SET_AUTHENTICATED_USER',
                payload: {
                    authenticatedUser: user,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterPage);
