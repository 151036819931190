import * as React from 'react';
import {
    connect
} from 'react-redux';
import {
    Form, Formik, FormikValues
} from 'formik';
import {
    registerUser
} from '../../../api/user';
import {
    useEffect, useState
} from 'react';
import {
    getAuthUser
} from '../../../helpers/user';
import IUser from '../../../_typescript/interfaces/IUser';
import TextInput from '../../formElements/TextInput/TextInput';
import EmailInput from '../../formElements/EmailInput/EmailInput';
import PasswordInput from '../../formElements/PasswordInput/PasswordInput';
import FormSubmit from '../../formElements/FormSubmit/FormSubmit';
import FormRequestError from '../../formElements/FormRequestError/FormRequestError';
import CustomButton from '../../CustomButton/CustomButton';

interface IFormErrors {
    name: string,
    email: string,
    password: string
    password_confirmed: string;
    how_did_you_hear_about_us: string;
}

const passwordMinLength = 12;

const attemptRegistration = function (
    values: FormikValues,
    // eslint-disable-next-line no-unused-vars
    setSuccess: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    // eslint-disable-next-line no-unused-vars
    setError: (value: (((prevState: string) => string) | string)) => void,
    // eslint-disable-next-line no-unused-vars
    setSubmitting: (isSubmitting: boolean) => void
) {
    (async () => {
        const registerSuccess = await registerUser(
            values.name,
            values.email,
            values.password,
            values.how_did_you_hear_about_us
        );
        if (registerSuccess) {
            setSuccess(true);
        } else {
            setError('Bei der Registrierung ist ein Fehler aufgetreten');
        }
        setSubmitting(false);
    })();
};

const UserRegister = ({
    setActiveLoginTab,
}) => {
    const [user, setUser,] = useState(getAuthUser() as null | IUser);
    const [error, setError,] = useState(null as null | string);
    const [success, setSuccess,] = useState(false);

    useEffect(
        () => {
            setUser(getAuthUser);
        },
        []
    );

    useEffect(
        () => {
            if (user) {
                // navigate('/application');
            }
        },
        [user,]
    );

    if (success) {
        return <main>
            <div data-test-id="register-thank-you">
                <p>Vielen Dank für Ihre Registrierung.</p>
                <p>Bitte klicken Sie auf den Verifizierungslink in der E-Mail, die wir gerade an Sie versendet haben um die
                    Registrierung abzuschließen.
                </p>
                <p>Bitte prüfen Sie auch Ihren Spam-Ordner.</p>

            </div>
        </main>;
    }

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                password: '',
                password_confirmed: '',
                how_did_you_hear_about_us: '',
            }}
            validate={values => {
                const errors = {} as IFormErrors;
                if (!values.name) {
                    errors.name = 'Pflichtfeld';
                }
                if (!values.how_did_you_hear_about_us) {
                    errors.how_did_you_hear_about_us = 'Pflichtfeld';
                }
                if (!values.password) {
                    errors.password = 'Pflichtfeld';
                }
                if (!values.password_confirmed) {
                    errors.password_confirmed = 'Pflichtfeld';
                }
                if (values.password && values.password_confirmed && values.password !== values.password_confirmed) {
                    errors.password_confirmed = 'Passwörter stimmen nicht überein';
                }
                // Password error
                if (values.password.length < passwordMinLength) {
                    errors.password = `Das Passwort muss mindestens ${passwordMinLength} Zeichen lang sein.`;
                }
                //TODO: Add password blacklist
                if (values.email.length && values.password.includes(values.email)) {
                    errors.password = 'Das Passwort darf nicht die E-Mail Adresse beinhalten';
                }
                if (!values.email) {
                    errors.email = 'Pflichtfeld';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Ungültige E-Mail Adresse';
                }
                return errors;
            }}
            onSubmit={(
                values, {
                    setSubmitting,
                }
            ) => {
                return attemptRegistration(
                    values,
                    setSuccess,
                    setError,
                    setSubmitting
                );
            }}
        >
            {({
                isSubmitting,
                values,
            }) => {
                return (
                    <Form>
                        <TextInput
                            label="Vor- und Nachname"
                            value={values.name}
                            name="name"
                        />
                        <EmailInput
                            label="E-Mail"
                            value={values.email}
                            name="email"
                        />
                        <PasswordInput
                            label="Passwort"
                            value={values.password}
                            name="password"
                            autoComplete="new-password"
                        />
                        <PasswordInput
                            label="Passwort bestätigen"
                            value={values.password_confirmed}
                            name="password_confirmed"
                            autoComplete="new-password"
                        />
                        <TextInput
                            label="Wie sind Sie auf uns aufmerksam geworden?"
                            value={values.how_did_you_hear_about_us}
                            name="how_did_you_hear_about_us"
                        />
                        <CustomButton
                            style="link"
                            label="Bereits registriert? Hier gehts zum Login"
                            additionalClassName="linkWithArrow"
                            onClick={() => {
                                setActiveLoginTab({
                                    tabId: 'login',
                                });
                            }}
                        />
                        <FormSubmit
                            disabled={isSubmitting}
                            label="Registrieren"
                        />
                        {
                            error && <FormRequestError error={error}/>
                        }
                    </Form>
                );
            }}
        </Formik>
    );
};

const mapStateToProps = ({}) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        // setAuthenticatedUser: ({
        //     user,
        // }) => {
        //     return dispatch({
        //         type: 'SET_AUTHENTICATED_USER',
        //         payload: {
        //             authenticatedUser: user,
        //         },
        //     });
        // },
        setActiveLoginTab: ({
            tabId,
        }) => {
            return dispatch({
                type: 'SET_ACTIVE_LOGIN_TAB',
                payload: {
                    activeLoginTab: tabId,
                },
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRegister);
